<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :tableHeight="350"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
        :header="false"
        :footer="false"
        :multiColumn="false"
        :tableData="tableData">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称、任务名称等" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <Input v-model="result" type="textarea" maxlength="128" show-word-limit style="width: 100%; margin-top: 15px;" :autosize="{minRows: 3,maxRows: 5}" placeholder="检查结果"></Input>
    <Tag size="large" checkable :checked="result !== item" v-for="(item, index) in tagList" :key="index" @on-change="tabClick(item)" style="margin: 5px;">{{ item }}</Tag>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import {checkDocEdit} from "@/api/check";

export default {
  name: '',
  mixins: [pageList],
  components: {},
  data() {
    return {
      headerColumns: [
        {width: 250, title: '需检查资料', key: 'name', align: 'center'},
        {
          minWidth: 300, title: '资料检查结果', align: 'center', render: (h, params) => {
            return h('RadioGroup', {
                  props: {
                    value: this.baseData['资料状态'].find(item => item.value === params.row.result)?.name || '已提供'
                  },
                  on: {
                    'on-change': (resultValue) => {
                      this.docs.find(item => item.id === params.row.id).result = this.baseData['资料状态'].find(item => item.name === resultValue)?.value || ''
                    }
                  }
                },
                this.baseData['资料状态'].map(item => {
                  return h('Radio', {props: {label: item.name}})
                }))
          }
        },
        {
          minWidth: 200, title: '附件', align: 'center', render: (h, params) => {
            return h('ys-upload', {
              props: {
                headers: {token: this.token}, action: '/web/resource/UploadFile', defaultList: this.docs.find(item => item.id === params.row.id).fileList || [],
              },
              on: {
                "on-success": (data) => {
                  this.docs.find(item => item.id === params.row.id).fileIds = data.map(item => item.id)
                  this.docs.find(item => item.id === params.row.id).fileList = data
                }
              }
            })
          }
        },
      ],
      params: {
        keyword: '',
      },
      result: '',
      tagList: [],
      tableData: [],
      docs: []
    }
  },
  computed: {},
  mounted() {
    this.modal = this.$parent.$parent;
    this.tableData = this.midData[0].itemList
    this.docs = this.tableData.map(item => {
      return {
        id: item.id,
        result: item.result,
        fileIds: (item.fileList || []).map(item => item.id),
        fileList: item.fileList
      }
    })
    this.result = this.midData[0].result
    this.tagList = this.midData[0].tag ? this.midData[0].tag.split(';') : []
    // this.tagList = '快捷录入1;快捷录入2;快捷录入3'.split(';')
  },
  methods: {
    tabClick(value) {
      this.result = value
    },
    close() {
      this.modal.modShow = false;
    },
    ok() {
      checkDocEdit({id: this.midData[0].id, result: this.result, docs: this.docs}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: "提示！",
            desc: '操作成功！'
          });
          this.$emit('on-ok', this.tableSelectItem);
          this.close();
        }
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
  }

}
</script>
